import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Spring, animated } from 'react-spring'
import { interpolate } from 'flubber'

const paths = [
  "M1.72,64.25C2.05,106.1,34,128.81,65.2,127.62v.91C-21.61,129.42-21.86-1.6,65.2,0V.74C33.79-.34,1.71,22.7,1.72,64.25Z",
  "M15.5,42C3.67,77,26.22,128.6,65.14,126.78v-.13C-21.69,130.34-21.74-3.59,65.14.07V.66C43.89.39,23.31,13.46,15.5,42Z",
  "M44.9,10.78c-24,24.79-23.21,113.57,20.25,115.61v.27C-21.66,130.31-21.78-3.51,65.15.07V.19C57.47.51,50.29,4.31,44.9,10.78Z",
  "M39.33,65.34c.28,20.42,2.72,55.47,25.89,63.11v.08C-21.54,130.23-21.94-.6,65.22,0V.67C45.87,5,38.66,35.33,39.33,65.34Z",
  "M50.24,66.22c-.3,6.42,1,52.83,14.91,60.44C-21.66,130.31-21.78-3.51,65.15.07,50.76,6.89,49.94,57.57,50.24,66.22Z",
  "M65.2,0V128.54C-21.55,129.45-21.91-1.61,65.2,0Z",
  "M68.87,122.73C83.22,85.65,82.59,40.29,69,3,40.82-10.22-.37,21.9,0,62.36-.75,103.68,41,135.18,68.87,122.73Z",
  "M70,125.71C94.62,99.33,98.85,17.29,62.78,0-27.19-.93-16.61,142.22,70,125.71Z",
  "M73.82,124.82C108.24,99.87,109.31,11.26,62.73,0-31-.93-13.4,148.63,73.82,124.82Z",
  "M94.69,117.21c34-26.6,32.22-85.23-3.83-109.69C84.93,1.53,72.49.36,62.69,0-49.14,3.62,5.65,174.29,94.69,117.21Z",
  "M128.66,63.35C127.6,147.77,1.74,147.79,0,63,2.21-20.95,127.56-21.18,128.66,63.35Z"
]

const interpolators = []
for (let i = 0; i < paths.length; i++) {
  interpolators.push(
    interpolate(paths[i], paths[i + 1] || paths[0], { maxSegmentLength: 10 })
  )
}

const Moon = (props) => {
  const countdown = useRef(null)
  const timeoutRef = useRef(null)

  const [state, setState] = useState({
      interpolators,
      timerStartTime: null,
      index: 0,
      isRunning: false,
      haltedDuration: null
    })

  useEffect(() => {
      if (props.halted) {
        const currentTime = Date.now()
        setState({...state, isRunning: false})
        setState({ ...state, haltedDuration: props.duration - (currentTime - state.timerStartTime) / 10})
        clearInterval(countdown.current)
      } else start()
    // TODO: this is quick fix can be done better but changing the initial isRunning prop brings new problems for now
    if (props.currentSlideNumber === 0) {
      start()
    }
    return () => stop()
  }, [props.halted])

  useEffect(()=> {
    if(!props.halted) {
      clearInterval(countdown.current);
      start()
    }
    return () => stop()
  }, [props.duration])

  useEffect(()=> {
    if(!props.halted) {
      start()
      setState({
        ...state,
        index: 0
      })
    }
    return () => stop()
  }, [props.currentSlideNumber])


  const start = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setInterval(()=> {
      goNext()
    }, (props.duration + 1000) / paths.length)
  }

  const stop = () => {
    if (timeoutRef.current === null) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  };

  const goNext = () =>
    setState(({ index, interpolators }) => ({
      ...state ,index: index + 1 >= interpolators.length ? 0 : index + 1,
    }))

    const interpolator = interpolators[state.index]
    const width = props.width
    const border = width * 0.06
    let color = "#131313"
    if (props.color === "white") color = "#FAF7F3"
    return (
      <div
        style={{
          width: `${width}px`,
          height:`${width}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div className="circle" style={{
          width: `${width}px`,
          height: `${width}px`,
          border: `solid ${border}px ${color}`,
          borderRadius: `100%`,
          overFlow: "hidden",
        }}>
          <svg width={width} style={{position: "relative", bottom: `${border}`, right: `${border}`}} viewBox="0 0 130 130">
            <g fill={color}>
              <Spring
                reset
                native
                from={{ t: 0 }}
                to={{ t: 1 }}
                >
                {({ t }) => <animated.path d={t.interpolate(interpolator)} />}
              </Spring>
            </g>
          </svg>
        </div>
      </div>
    )
}

Moon.propTypes = {
  duration: PropTypes.number,
  width: PropTypes.number,
  halted: PropTypes.bool,
}


export default Moon