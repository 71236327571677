import React, { useCallback, useEffect } from "react"
import "./styles.sass"
import { Store } from "../../../store/Store";
import { useSpring, animated, config } from 'react-spring'

const CardOpenAnimation = () => {

  const [props, set] = useSpring(() => ({opacity: 0, marginTop: "15px",marginLeft: "0px",width: "50%", height: "80%", onRest: () => finish(), config: config.stiff}))
  const { state, dispatch } = Store();

  const start = useCallback(() => {
    set({opacity: 1, width: "110%", height: "100%", marginTop: "0px",marginLeft: "-5px"})
  }, [set])

  useEffect(()=> {
    if (state.cardAnimationIsRunning) start()
  }, [set, start, state])

  const finish = () => {
    dispatch({type: "showCase", payload: state.cardAnimationData})
    dispatch({type: "stopCardAnimation"})
  }

  return (
    <div className="card-open-animation-wrapper">
      <animated.div className="animation-portfolio-card" style={props}>
        <div className="inner-card">
          <div className="left-wrapper">
            {/*<div className="portfolio-card--position">*/}
            {/*  {position > 10 ? position : `0${position}`}*/}
            {/*</div>*/}
            <div className="portfolio-card--title">
              {/*{name}*/}
            </div>
          </div>
          <div className="right-wrapper">
            <div className="portfolio-card--indicator">
              {/*{name}*/}
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  )}

export default CardOpenAnimation
