import React, { useState, useEffect } from "react"
import {Transition} from "react-transition-group"
import Moon from "../moon"
import "./styles.sass"


const LoadingSpinner = ({ isVisible, backgroundColor, spinnerSize, zIndex }) => {
  const [render, setRender] = useState(true);

  const duration = 500;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 1, zIndex: zIndex },
    entered:  { opacity: 1, zIndex: zIndex },
    exiting:  { opacity: 0, zIndex: zIndex },
    exited:  { opacity: 0, zIndex: zIndex,
    display: 'none'},
  };

  useEffect(() => {
    if(isVisible && !render) setRender(true)
  }, [isVisible, render])

  const spinnerColor = backgroundColor === 'red' ? 'black' : 'white'

  const handleExit = () => setRender(false)

  return (
    <>
      {render ? <Transition  in={isVisible} timeout={500} appear={true} onExited={handleExit}>
        {state => (
          <div className={`loading-spinner-wrapper ${backgroundColor}`} style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <Moon color={spinnerColor} duration={5000} halted={false} currentSlideNumber={0} width={spinnerSize}/>
          </div>
        )}
      </Transition>: null}
    </>
  )
}

export default LoadingSpinner
