import React from "react"
import { Link } from "gatsby"
import SmoothScrollLink from "../smoothScrollLink"

import "./styles.sass"

const FrontNav = ({ color, bottom }) => {
  const containerClass = `front-navigation ${color} hide-on-mobile`
  return (
    <div className={containerClass}>
      {bottom}
      {bottom === true && (
        <div className="front-navigation--link">
          <a href="https://www.instagram.com/co_magick/">
            Instagram
          </a>
        </div>
      )}
      <div className="front-navigation--link">
        <SmoothScrollLink name="work" id="work" containerId="section-wrapper"/>
      </div>
      <div className="front-navigation--link">
        <SmoothScrollLink name="secrets" id="secrets" containerId="section-wrapper"/>
      </div>
      {!bottom && (
      <div className="front-navigation--link">
        <SmoothScrollLink name="contact" id="contact" containerId="section-wrapper"/>
      </div>
      )}
      {bottom === true && (
      <div className="front-navigation--link">
        <Link className="muted" to="/imprint">
        Imprint
        </Link>
      </div>)}
      {bottom === true && (
        <div className="front-navigation--link">
        <Link className="muted" to="/privacy">
        Privacy
        </Link>
      </div>)}
    </div>
  )}

export default React.memo(FrontNav)
