import React from "react"
import "./styles.sass"
import { Store } from "../../store/Store";

const MobileFrontNavButton = ({color, bottom}) => {
  const {dispatch} = Store()

  const openMobileMenu = () => dispatch({type: "openMobileMenu"})
  return (
    <div className={`mobile-front-nav-button-wrapper hide-on-desktop ${color}`}>
      <div className="mobile-front-nav-button" onClick={openMobileMenu}>
        MENU
      </div>
      {bottom && (
        <div className="social-media">
          <a href="https://www.instagram.com/co_magick/">Instagram</a>
        </div>
      )}
    </div>
  )}

export default MobileFrontNavButton
