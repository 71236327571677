import React, { useRef, useEffect, useState} from "react"
import Loadable from "@loadable/component"

import { Store } from "../../store/Store";
import Layout from "../../components/layout"
import SectionWrapper from "../../components/section-wrapper"
import SideBar from "../../components/sideNav"
import Logo from "../../components/logo"
import FrontNav from "../../components/frontNav"
import MobileFrontNavButton from "../../components/mobileFrontNavButton"
import useWindowSize from "../../hooks/useWindowSize"
import LoadingSpinner from "../../components/loadingSpinner"

import "./styles.sass"
import CardOpenAnimation from "../../components/portfolio/cardOpenAnimation"

// lazy loading the bigger components
const SpoonScene = Loadable(() => import("../../components/spoonScene/alternative"))
const Manifesto = Loadable(() => import("../../components/manifesto"))
const Contact = Loadable(() => import("../../components/contact"))

// the portfolio includes cardDeck as another lazy loaded component, with this load here we will decfrease graphQL loading time
const Portfolio = Loadable(() => import("../../components/portfolio"))

const structure = [
  {
    id: null,
    name: null
  },
  {
    id: "#spoon",
    name: 'spoon'
  },
  {
    id: "#work",
    name: 'work'
  },
  {
    id: "#secrets",
    name: 'secrets'
  },
  {
    id: "#contact",
    name: 'contact'
  }
]

const FrontPage = () => {
  const {state, dispatch} = Store()
  const windowSizeHook = useWindowSize()

  useEffect(()=> {
    const heightTolerance = 50; // Approximately URL bar height in Chrome on tablet
    let viewportHeight = window.innerHeight
    if (state.windowSize.height !== window.innerHeight) dispatch({type: "setWindowSize", payload: {width: window.innerWidth, height: window.innerHeight}})

    const resizeHandler = () =>{
      if (state.windowSize.width !== window.innerWidth) dispatch({type: "setWindowSize", payload: {width: window.innerWidth, height: window.innerHeight}})
      if (Math.abs(viewportHeight - window.innerHeight) > heightTolerance && (state.windowSize.height !== windowSizeHook.height || state.windowSize.width !== windowSizeHook.width)) {
        dispatch({type: "setWindowSize", payload: {width: window.innerWidth, height: window.innerHeight}})
      }
    }
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  })

  const [spoonLoaded, setSpoonLoaded] = useState(false)
  const handleFrontSpoonLoaded = () => setSpoonLoaded(true)

  // if(state.spoonIsShutDown) dispatch({type: "enableSpoon"})


  // mobile browsers don't work well with css vh, if it is a mobile we take the innerHeight that we calculated at initial render
  const mobileBrowserHeight = state.windowSize.height !== null ? `${state.windowSize.height}px` : '100vh'
  const  mobileBrowserHeightStyle = {height: mobileBrowserHeight}

  const spoonRef = useRef(null);

  const suspenseLoader = () => <LoadingSpinner backgroundColor={'red'} isVisible={true} spinnerSize={40} zIndex={0}/>
  const suspenseLoaderBlack = () => <LoadingSpinner backgroundColor={'black'} isVisible={true} spinnerSize={40} zIndex={0}/>

  return (
    <Layout >
      <SectionWrapper>
        <section style={{height: mobileBrowserHeight, minHeight: mobileBrowserHeight}} ref={spoonRef} className="full-size-bg noise red-bg" id="spoon">
          <SpoonScene fallback={suspenseLoader()} customRef={spoonRef} onLoaded={handleFrontSpoonLoaded}/>
          <LoadingSpinner backgroundColor={'red'} isVisible={!spoonLoaded} spinnerSize={40} zIndex={0}/>
          <div className="in-front">
            <Logo/>
            <FrontNav/>
            <MobileFrontNavButton/>
          </div>
          <SideBar
            color="black"
            tiny={state.windowSize.width < 600}
            previous={structure[0]}
            current={structure[0]}
            next={structure[2]}
          />
        </section>
        <section style={mobileBrowserHeightStyle} className="full-size-bg noise black-bg" id="work">
          <Portfolio fallback={suspenseLoaderBlack()} fallbackTransport={suspenseLoaderBlack}/>
          {state.cardAnimationIsRunning && <CardOpenAnimation/>}
          <SideBar
            moveBack={true}
            color="white"
            previous={structure[1]}
            current={structure[2]}
            next={structure[3]}
            hideOnMobile={['current', 'previous']}
            tiny={state.windowSize.width < 600}
          />
        </section>

        <section style={mobileBrowserHeightStyle} className="full-size-bg noise red-bg" id="secrets">
          <Manifesto fallback={suspenseLoader()}/>
          <SideBar
            color="black"
            previous={structure[2]}
            current={structure[3]}
            next={structure[4]}
            hideOnMobile={['current']}
          />
        </section>
        <section style={mobileBrowserHeightStyle} className="full-size-bg noise black-bg overflow-hidden" id="contact">
          <Contact fallback={suspenseLoaderBlack()}/>
          <SideBar
            color="white"
            previous={structure[3]}
            current={structure[4]}
            next={structure[0]}
            hideOnMobile={['current']}
          />
        </section>
      </SectionWrapper>
    </Layout>
  )}

export default React.forwardRef(FrontPage)
