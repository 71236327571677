import React from "react"
import PropTypes from "prop-types"
import { Store } from "../../store/Store";


import "./styles.sass"

const SectionWrapper = ({ children }) => {
  const {state} = Store()
  // mobile browsers don't work well with css vh, if it is a mobile we take the innerHeight that we calculated at initial render
  const mobileBrowserHeight = state.windowSize.height !== null ? `${state.windowSize.height}px` : '100vh'

  return (
    <div className="scroll-wrapper"
         style={{
           scrollSnapType: state.activeSnapping ?  "y mandatory" : "none",
           height: mobileBrowserHeight,
           overflowY: state.overflowHidden ? 'hidden' : 'scroll',
           touchAction:state.overflowHidden ?  "none" : "auto",
           position: state.overflowHidden ?  "fixed" : "auto"
         }}
         id="section-wrapper">
      {children}
    </div>
  )
}

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionWrapper
