import React from "react"
import "./styles.sass"
import SVGLogo from "../../assets/C_O_MAGICK__LOGO.svg"

const Logo = () => {
    return(
        <div className="logo-container">
          <div className="svg-container">
            <SVGLogo></SVGLogo>
          </div>
        </div>
    )
}

export default React.memo(Logo);