import React from "react"
import SmoothScrollLink from "../smoothScrollLink"

import "./styles.sass"



const SideNav = ({ color, previous, current, next, hideOnMobile, moveBack, tiny }) => {
  const doNotDisplay = (type) => {
    if (!!hideOnMobile && hideOnMobile.length > 0) {
      return hideOnMobile.includes(type)
    }
    else {
      return false
    }
  };
  const sideNavClasses = `side-nav ${color} ${tiny ? 'tiny' : ''}`
  const containerId = "section-wrapper"
  return (
    <div className={sideNavClasses} style={ moveBack && {zIndex: 0}}>
        <div className={`previous ${doNotDisplay('previous') ? 'visible-only-desktop' : ""}`}>
          <SmoothScrollLink name={previous.name} id={previous.name} containerId={containerId} />
          {previous.name !== null &&
          <div className="previous--line" />
          }
        </div>
        <div className={`current ${doNotDisplay('current') ? 'visible-only-desktop' : ""}`}>
          <SmoothScrollLink name={current.name} id={current.name} containerId={containerId} />
          {current.name !== null &&
          <div className="current--line" />
          }
        </div>
      <div className={`next ${doNotDisplay('next') ? 'visible-only-desktop' : ""}`}>
        <SmoothScrollLink name={next.name} id={next.name} containerId={containerId} />
        {next.name !== null &&
          <div className="next--line" />
        }
        </div>
    </div>
)}


SideNav.defaultProps = {
  color: 'black',
  previous: {
    name: null,
    id: null
  },
  current: {
    name: "work",
    id: "#work"
  },
  next: {
    name: "manifesto",
    id: "#manifesto"
  }
}

export default React.memo(SideNav)
